body {
  margin: 0;
  padding: 0;
}

* {
  font-family: 'Poppins', sans-serif;
}

html, body, #app, #app>div, #root, .page {
  height: 100%
}

.page {
  display: flex;
  flex-direction: column;
}

a {
  color: #484848;
}