.background {
    position: relative;
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-image: url('../assets/you-x-ventures-Oalh2MojUuk-unsplash.jpg');  */
    /* background-position: center; */
    /* Center the image */
    /* background-repeat: no-repeat; */
    /* Do not repeat the image */
    /* background-size: cover; */
    /* Resize the background image to cover the entire container
}


.background:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #297A6D;
    opacity: 0.4;
}

.navContainer {
    display: flex;
    /* flex-direction: column; */
}

.leftNav {
    flex: 2;
}

.midNav {
    flex: 4;
    text-align: center;
}

.rightNav {
    flex: 2;
    text-align: right
}

.quizStart {
    /* border-radius: 20px !important; */
}

.quizStart:hover {
    color: #e91e63;
    background-color: white !important;
    box-shadow: inset 0px 0px 0px 2px #e91e63;
    box-sizing: border-box;
}

.banner_content {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    margin: auto;
    color: black;
}

.subtext {
    color: #297A6D;
    padding: 4px;
}

.instaIcon {
    padding: 4px;
}

.divider {
    padding: 4px;
    color: black;
}

.footer {
    display: flex;
}

h1, h3 {
    color: white;
    text-align: center;
}

h1 {
    margin-bottom: 0px;
}

h3 {
    font-weight: 100;
}