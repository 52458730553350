.bodyContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pearAvatarContainer {
    flex: 4;
    margin: 8px !important;
}

.pearAvatar {
    max-height: 100%;
    margin: auto;
    max-width: 100%;
}

.quizInfo {
    flex: 1;
    text-align: center;
}

.leftContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 0px !important;
    padding: 0px! important;
}

.quizProgress {
    align-self: flex-end;
    width: 100%;
}

.progressBar {
    height: 15px !important;
}

.options {
    display: block !important;
}

.questionNumber {
    flex: 0.75;
    /* padding: 15px; */
    width: 90%;
    text-align: center;
}

.questionNumberText {
    color: #e91e63 !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: auto !important;
    padding: 0px 15px 0px 15px;
}

.signUpForm {
    margin: auto;
}

.rightContainer {
    text-align: center;
    background-color: #77a6f7;
}

.questionGrid {
    height: 100% !important;
    width: 100% !important;
    margin: 0px !important;
    flex-direction: column;
    display: flex;
    align-content: space-between;
    flex-wrap: nowrap !important;
}

.signUpForm {
    width: 90% !important;
    margin: auto !important;
}

.submitButton {
    margin-top: 16px !important;
    position: initial;
    width: 100%;
}

.questionNumberTextMobile {
    color: #e91e63 !important;
    font-size: 1rem !important;
    width: 100%;
    align-self: center !important;
}

.innerPearContainer {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@media only screen and (max-width: 600px) {
    .divider {
        margin-bottom: -2.2rem;
    }
}