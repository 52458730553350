.App {
  text-align: center;
  min-height: 100%;
}

* {
  font-family: 'Poppins', sans-serif;
}

@font-face {
  font-family: 'Campton';
  src: url('../assets/Campton-LightDEMO.woff2')
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.container {
  display: flex;
  min-height:100vh;
  flex-direction: column;
  flex-grow: 1;
}

.title {
  flex: 1;
}

.content {
  flex: 1;
  display: flex;
}

.buttonContainer {
  min-width: 25vw;
  text-align: center;
}

.withLine {
  width:inherit;
  position: relative;
  z-index: 1;
  background:#fff;
}

.withLine::before {
  width: inherit;
  border-top: 1px solid #9e9e9e;
  content:"";
  margin: 0 auto; /* this centers the line to the full width specified */
  position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
  top: 50%; left: 0; right: 0; bottom: 0;
  width: 95%;
  z-index: -1;
}

.lineBehind {
  z-index: 10;
  background-color:white;
  width: fit-content;
  margin: auto !important;
  padding-left: 5px;
  padding-right: 5px;
}