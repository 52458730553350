@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
body {
  margin: 0;
  padding: 0;
}

* {
  font-family: 'Poppins', sans-serif;
}

html, body, #app, #app>div, #root, .page {
  height: 100%
}

.page {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

a {
  color: #484848;
}
.App {
  text-align: center;
  min-height: 100%;
}

* {
  font-family: 'Poppins', sans-serif;
}

@font-face {
  font-family: 'Campton';
  src: url(/static/media/Campton-LightDEMO.0b5fc0ed.woff2)
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.container {
  display: -webkit-flex;
  display: flex;
  min-height:100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.title {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.content {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
}

.buttonContainer {
  min-width: 25vw;
  text-align: center;
}

.withLine {
  width:inherit;
  position: relative;
  z-index: 1;
  background:#fff;
}

.withLine::before {
  width: inherit;
  border-top: 1px solid #9e9e9e;
  content:"";
  margin: 0 auto; /* this centers the line to the full width specified */
  position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
  top: 50%; left: 0; right: 0; bottom: 0;
  width: 95%;
  z-index: -1;
}

.lineBehind {
  z-index: 10;
  background-color:white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto !important;
  padding-left: 5px;
  padding-right: 5px;
}
.topBar {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.navButtons {
    display: -webkit-flex;
    display: flex;
}

.pearLogo {
    max-height: 40px;
}

.loginModal {
    max-width: 100%;
}

a {
    color: #ff5a5f;
}

.input {
    width: 100%;
}

.nav {
    z-index: 1;
    width: 100%;
}

.signUpButton {
    margin-top: 4px !important;
}


.appbar {
    box-shadow: none !important;
    background-color: transparent !important;
    position: relative !important;
    transition: background-color 300ms ease-in-out;
    -moz-transition: background-color 300ms ease-in-out;
    -webkit-transition: background-color 300ms ease-in-out;
    -o-transition: background-color 300ms ease-in-out;
}

.appbarsmall {
    box-shadow: none !important;
    background-color: transparent !important;
}

.appbar:hover {
    background-color: rgba(255, 255, 255, 0.85) !important;
}

.pearLogo {
    margin: auto;
}

.signInLink {
    text-align: center;
}
.pwForgetLink {
    text-align: center;
}
.signUpLink {
    text-align: center;
}
/* 
[class*='MuiOutlined'] {
    border-color: white !important;
} */
.background {
    position: relative;
    width: 100%;
    min-height: 400px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    /* background-image: url('../assets/you-x-ventures-Oalh2MojUuk-unsplash.jpg');  */
    /* background-position: center; */
    /* Center the image */
    /* background-repeat: no-repeat; */
    /* Do not repeat the image */
    /* background-size: cover; */
    /* Resize the background image to cover the entire container
}


.background:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #297A6D;
    opacity: 0.4;
}

.navContainer {
    display: flex;
    /* flex-direction: column; */
}

.leftNav {
    -webkit-flex: 2 1;
            flex: 2 1;
}

.midNav {
    -webkit-flex: 4 1;
            flex: 4 1;
    text-align: center;
}

.rightNav {
    -webkit-flex: 2 1;
            flex: 2 1;
    text-align: right
}

.quizStart {
    /* border-radius: 20px !important; */
}

.quizStart:hover {
    color: #e91e63;
    background-color: white !important;
    box-shadow: inset 0px 0px 0px 2px #e91e63;
    box-sizing: border-box;
}

.banner_content {
    position: relative;
    z-index: 10;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    margin: auto;
    color: black;
}

.subtext {
    color: #297A6D;
    padding: 4px;
}

.instaIcon {
    padding: 4px;
}

.divider {
    padding: 4px;
    color: black;
}

.footer {
    display: -webkit-flex;
    display: flex;
}

h1, h3 {
    color: white;
    text-align: center;
}

h1 {
    margin-bottom: 0px;
}

h3 {
    font-weight: 100;
}
.bodyContent {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
}

.pearAvatarContainer {
    -webkit-flex: 4 1;
            flex: 4 1;
    margin: 8px !important;
}

.pearAvatar {
    max-height: 100%;
    margin: auto;
    max-width: 100%;
}

.quizInfo {
    -webkit-flex: 1 1;
            flex: 1 1;
    text-align: center;
}

.leftContainer {
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0px !important;
    padding: 0px! important;
}

.quizProgress {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    width: 100%;
}

.progressBar {
    height: 15px !important;
}

.options {
    display: block !important;
}

.questionNumber {
    -webkit-flex: 0.75 1;
            flex: 0.75 1;
    /* padding: 15px; */
    width: 90%;
    text-align: center;
}

.questionNumberText {
    color: #e91e63 !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: auto !important;
    padding: 0px 15px 0px 15px;
}

.signUpForm {
    margin: auto;
}

.rightContainer {
    text-align: center;
    background-color: #77a6f7;
}

.questionGrid {
    height: 100% !important;
    width: 100% !important;
    margin: 0px !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: space-between;
            align-content: space-between;
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
}

.signUpForm {
    width: 90% !important;
    margin: auto !important;
}

.submitButton {
    margin-top: 16px !important;
    position: static;
    position: initial;
    width: 100%;
} 

.questionNumberTextMobile {
    color: #e91e63 !important;
    font-size: 1rem !important;
    width: 100%;
    -webkit-align-self: center !important;
            align-self: center !important;
}

@media only screen and (max-width: 600px) {
    .divider {
      margin-bottom: -2.2rem;
    }
  }
.bodyContent {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
}

.pearAvatarContainer {
    -webkit-flex: 4 1;
            flex: 4 1;
    margin: 8px !important;
}

.pearAvatar {
    max-height: 100%;
    margin: auto;
    max-width: 100%;
}

.quizInfo {
    -webkit-flex: 1 1;
            flex: 1 1;
    text-align: center;
}

.leftContainer {
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0px !important;
    padding: 0px! important;
}

.quizProgress {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    width: 100%;
}

.progressBar {
    height: 15px !important;
}

.options {
    display: block !important;
}

.questionNumber {
    -webkit-flex: 0.75 1;
            flex: 0.75 1;
    /* padding: 15px; */
    width: 90%;
    text-align: center;
}

.questionNumberText {
    color: #e91e63 !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: auto !important;
    padding: 0px 15px 0px 15px;
}

.signUpForm {
    margin: auto;
}

.rightContainer {
    text-align: center;
    background-color: #77a6f7;
}

.questionGrid {
    height: 100% !important;
    width: 100% !important;
    margin: 0px !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: space-between;
            align-content: space-between;
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
}

.signUpForm {
    width: 90% !important;
    margin: auto !important;
}

.submitButton {
    margin-top: 16px !important;
    position: static;
    position: initial;
    width: 100%;
}

.questionNumberTextMobile {
    color: #e91e63 !important;
    font-size: 1rem !important;
    width: 100%;
    -webkit-align-self: center !important;
            align-self: center !important;
}

.innerPearContainer {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

@media only screen and (max-width: 600px) {
    .divider {
        margin-bottom: -2.2rem;
    }
}
.answer {
    -webkit-flex: 1 1 !important;
            flex: 1 1 !important;
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
}

.normalRadio {
    height: 100%;
}

.quizOption:hover .radio {
    color: white !important;
    background-color: #e91e63 !important;
}

.radio {
    color: white !important;
}

.quizOptionsGrid {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.questionTextBorder {
    width: 80%;
    border-bottom: 2px dotted white;
    padding-top: 15px;
    -webkit-flex: 1 1 !important;
            flex: 1 1 !important;
}

.outerAnswers {
    -webkit-flex: 4 1 !important;
            flex: 4 1 !important;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 8px !important;
}

.radio {
    background-color: white !important;
    color: #77a6f7 !important;
}
.contactContainer {
    padding: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}

.cardContainer {
    max-width: 1000px;
}

.contactCard {
    width: 100%;  
    text-align: center; 
}

.contactCardPhoto {
    margin-top: 16px !important;
    width: 120px !important;
    height: 120px !important;
    margin: auto;
}
